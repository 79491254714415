import 'models/init';

import React, { useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { hot } from 'react-hot-loader';
import { Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { REACT_APP_GOOGLE_CLIENT_ID } from 'consts';
import { useGate } from 'effector-react';
import {
  useHandleChatsPositioning,
  useHaveToken,
  useHideChats,
  useInit,
} from 'hooks';
import MainLayout from 'layout/mainLayout';
import { territoryIDGate } from 'models/common';
import { jobTypesGate } from 'models/jobTypes';
import { categoriesGate } from 'models/product';
import { Routes } from 'routes';

import { ErrorFallback } from 'features/common/ErrorFallback';

import { history } from 'libs/history';

import Tracking from './Tracking';

import 'assets/styles/index.scss';
//preload header/footer backgrounds
// eslint-disable-next-line no-unused-vars
import footerBackground from 'assets/images/picture/footerBackgroud.png';
// eslint-disable-next-line no-unused-vars
import headerBackground from 'assets/images/picture/headerBackground.svg';

import './app.module.scss';

export const useErrorHandler = () => {
  const handleError = useCallback((error) => {
    const errorText = error ? error.toString() : '';
    const isErrorRelatedToChunk = /Loading .*| chunk/.test(errorText);

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry') || 'false',
    );

    if (!isErrorRelatedToChunk) {
      return;
    }

    if (hasRefreshed) {
      console.warn('Loading chunk error happened', window.location.href);
      window.sessionStorage.setItem('retry', 'false');
    } else {
      window.sessionStorage.setItem('retry', 'true');
      window.location.reload();
    }
  }, []);

  return useMemo(() => ({ handleError }), [handleError]);
};

const App = () => {
  useInit();
  useHaveToken();
  useHideChats({ isAlwaysHide: false, isHideOnMobile: false });
  useHandleChatsPositioning();
  const { handleError } = useErrorHandler();

  useGate(categoriesGate);
  useGate(jobTypesGate);
  useGate(territoryIDGate);

  return (
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <Router history={history}>
          <Tracking />
          <MainLayout>
            <Routes />
          </MainLayout>
        </Router>
      </ErrorBoundary>
    </GoogleOAuthProvider>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
