import { END_DATE, START_DATE } from '@datepicker-react/hooks';
import {
  DEFAULT_DELIVERY_METHODS,
  REACT_APP_PREFIX,
  SELF_COLLECTION,
} from 'consts';
import { differenceInCalendarDays } from 'date-fns';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  guard,
  merge,
  restore,
} from 'effector';
import { persist } from 'effector-storage/local';
import { addProductToReservation } from 'googleAnalytics';
import { settings$ } from 'models/settings';
import { $isUserLoggedIn, $userInfo } from 'models/user';
import { getDate } from 'utils/datesСalculation';
import {
  getFormattedProductsForTotalPriceRequest,
  getProductsForGoogleAnalytics,
} from 'utils/formatProducts';
import { getObject } from 'utils/localStorage';

import { CHECKOUT, DISCOUNTS, QUERIES } from 'api';

import { branding$ } from '../branding';

export const changeSize = createEvent();

export const changeDatesRange = createEvent();
export const changeStartDay = createEvent();
export const changeEndDay = createEvent();
export const changeFocusedPickerDay = createEvent();

export const changeDelivery = createEvent();
export const changeDeliveryMethod = createEvent();
export const changeDeliveryIsAvailable = createEvent();
export const changeDeliveryWarehouse = createEvent();
export const changeDeliveryAddress = createEvent();
export const changeDeliveryPostcodeAddress = createEvent();
export const clearDeliveryStorage = createEvent();

export const setLatestTotalPricePostcode = createEvent();
export const latestTotalPricePostcode$ = createStore(null).on(
  setLatestTotalPricePostcode,
  (_, p) => p,
);

export const setTotalPrice = createEvent();
export const setTotalPriceLoading = createEvent();

export const latestTotalPriceInfo$ = createStore({
  latestTotalPrice: {},
  isLoadingLatestTotalPrice: true,
})
  .on(setTotalPrice, (state, props) => {
    return { latestTotalPrice: { ...props }, isLoadingLatestTotalPrice: false };
  })
  .on(setTotalPriceLoading, (state, props) => {
    return {
      latestTotalPrice: { ...state.latestTotalPrice },
      isLoadingLatestTotalPrice: true,
    };
  });

export const getAllDisscountsFx = createEffect({
  handler: DISCOUNTS.getDiscounts,
});

export const allDiscounts$ = createStore([]).on(
  getAllDisscountsFx.doneData,
  (s, { data: { data } }) => data,
);

//CART ITEMS
export const addItemToCart = createEvent();
export const addItemsToCart = createEvent();
export const deleteItemFromCart = createEvent();
export const deleteOptionalExtraFromCart = createEvent();
export const deleteOptionalSaleExtraFromCart = createEvent();
export const editItemInCart = createEvent();
export const editAttributes = createEvent();
export const resetCart = createEvent();
export const resetCartDetails = createEvent();

export const cart$ = createStore([])
  .on(addItemsToCart, (s, p) => {
    const currency = branding$.getState().currency;
    p.forEach((el) => addProductToReservation(el, currency));
    return p;
  })
  .on(addItemToCart, (currCard, newItem) => {
    const cleanedItems = currCard.filter(({ id }) => id !== newItem.id);
    const res = cleanedItems.concat(newItem);
    addProductToReservation(res, branding$.getState().currency);
    return res;
  })
  .on(editItemInCart, (s, p) => {
    return s.map((item) => (item.id === p.id ? { ...item, ...p } : item));
  })

  .on(editAttributes, (s, { id, attributes, type }) => {
    const productIndex = s.findIndex((el) => el.id === id);

    if (productIndex === -1) {
      return s;
    }

    const copy = [...s];
    copy[productIndex] = { ...copy[productIndex], [type]: attributes };

    return copy;
  })

  .on(deleteItemFromCart, (s, p) => s.filter(({ id }) => id !== p))
  .on(deleteOptionalExtraFromCart, (s, p) => {
    return s.map((item) =>
      item.optionalExtra.id === p ? { ...item, optionalExtra: null } : item,
    );
  })
  .on(deleteOptionalSaleExtraFromCart, (s, p) => {
    return s.map((item) =>
      item.optionalSaleExtra.id === p
        ? { ...item, optionalSaleExtra: null }
        : item,
    );
  })
  .on(resetCart, (s, p) => []);

persist({ store: cart$, key: `cart_${REACT_APP_PREFIX}` });

//CART ATTRIBUTES FOR REQUEST
export const cartFormattedData$ = combine(
  cart$,
  settings$,
  (cart, settings) => {
    if (!cart || !cart.length) {
      return [];
    }

    return cart.map((el) => {
      return getFormattedProductsForTotalPriceRequest({
        ...el,
        extraConsumablesEnabled: settings.extraConsumablesEnabled,
      });
    });
  },
);

//CART TYPES
export const changeIsCartTypesAreTheSame = createEvent();

export const cartTypes$ = createStore({
  isCartTypesTheSame: true,
  isCartTypesHasSelfCollection: false,
}).on(cart$, (s, cart) => {
  const deliveryAttributes = cart.map((el) => el.product.delivery?.type);

  const isCartTypesTheSame = deliveryAttributes.every(
    (el) => el === deliveryAttributes[0],
  );
  const isCartTypesHasSelfCollection = deliveryAttributes.some(
    (el) => el === DEFAULT_DELIVERY_METHODS.indexOf(SELF_COLLECTION),
  );

  return {
    isCartTypesTheSame,
    isCartTypesHasSelfCollection,
  };
});

//products for selfCollection
export const productsForOnlySelfCollection$ = combine(
  cart$,
  cartTypes$,
  (cart, { isCartTypesHasSelfCollection }) => {
    if (!isCartTypesHasSelfCollection || !cart?.length) {
      return [];
    }

    return cart.reduce((acc, currVal) => {
      if (
        currVal.product.delivery?.type ===
        DEFAULT_DELIVERY_METHODS.indexOf(SELF_COLLECTION)
      ) {
        acc.push(currVal.product.name);
      }

      return acc;
    }, []);
  },
);

const deliveryMethodLocalStorage = getObject(
  `deliveryMethod_${REACT_APP_PREFIX}`,
) || {
  method: null,
  isAvailable: true,
  warehouse: null,
  address: {
    place_id: null,
    name: null,
    lat: null,
    lng: null,
    postcode: null,
    type: null,
  },
  addressByPostcode: {
    place_id: null,
    name: null,
    lat: null,
    lng: null,
    addressLine1: null,
    addressLine2: null,
    town: null,
    country: null,
  },
};

//DELIVERY METHOD
export const deliveryMethod$ = createStore({
  ...deliveryMethodLocalStorage,
})
  .on(changeDelivery, (s, p) => {
    return {
      ...p,
    };
  })
  .on(changeDeliveryMethod, (s, method) => ({ ...s, method }))
  .on(changeDeliveryWarehouse, (s, warehouse) => ({ ...s, warehouse }))
  .on(changeDeliveryAddress, (s, address) => {
    return {
      ...s,
      address: {
        ...s.address,
        type: address.place_id || address.postcode ? 'bothWaysDelivery' : null,
        ...address,
      },
    };
  })
  .on(changeDeliveryPostcodeAddress, (s, addressByPostcode) => {
    return {
      ...s,
      addressByPostcode: {
        ...addressByPostcode,
      },
    };
  })
  .on(clearDeliveryStorage, (s, p) => {
    return {
      method: null,
      isAvailable: true,
      warehouse: null,
      address: {
        place_id: null,
        name: null,
        lat: null,
        lng: null,
        postcode: null,
        type: null,
      },
      addressByPostcode: {
        place_id: null,
        name: null,
        lat: null,
        lng: null,
        addressLine1: null,
        addressLine2: null,
        town: null,
        country: null,
      },
    };
  });

persist({ store: deliveryMethod$, key: `deliveryMethod_${REACT_APP_PREFIX}` });

//POSTCODE WORK
export const changePostCode = createEvent();
export const setPostcodeError = createEvent();
export const changePostCodeInput = changePostCode.prepend(
  (e) => e.target.value,
);

export const cartDetails$ = createStore({})
  .on(changeStartDay, (s, p) => ({
    ...s,
    start: p?.getTime(),
  }))
  .on(changeEndDay, (s, p) => ({
    ...s,
    end: p?.getTime(),
  }))
  .on(changePostCode, (s, postcode) => {
    return {
      ...s,
      postcode,
    };
  })
  .on(changeSize, (s, size) => ({
    ...s,
    size,
  }))
  .reset(resetCartDetails);

persist({ store: cartDetails$, key: 'cartDetails' });

export const focusedInput$ = createStore(START_DATE)
  .on(changeDatesRange, (s, { focusedInput, startDate, endDate }) => {
    if (startDate) return END_DATE;
    if (endDate) return START_DATE;
  })
  .on(changeFocusedPickerDay, (s, p) => {
    if (p === 'start') return START_DATE;
    if (p === 'end') return END_DATE;
  });

export const picker_state$ = combine(
  cartDetails$,
  focusedInput$,
  ({ start, end }, focusedInput) => {
    return {
      startDate: getDate(start),
      endDate: getDate(end),
      focusedInput,
    };
  },
);

export const selectedDiscount$ = combine(
  picker_state$,
  allDiscounts$,
  ({ startDate }, discounts) =>
    discounts
      .filter(
        ({ days }) =>
          differenceInCalendarDays(startDate, new Date()) > Number(days),
      )
      .reduce((acc, discount) => {
        if (Number(acc?.days) > Number(discount.days)) return acc;
        if (
          differenceInCalendarDays(startDate, new Date()) >
          Number(discount.days)
        )
          return discount;
        else return acc;
      }, {}) || {},
);

const cartChangesGoogleAnalytics = createEvent();

export const productsForGoogleAnalytics$ = createStore([]).on(
  cartChangesGoogleAnalytics,
  (s, p) => {
    return getProductsForGoogleAnalytics(p);
  },
);

guard({
  source: cart$.updates,
  filter: (cart) => cart && cart.length,
  target: cartChangesGoogleAnalytics,
});

export const createFakeOrderFx = createEffect({
  handler: async ({ checkoutData, config }) => {
    const { data } = await QUERIES.createFakeOrder(checkoutData, config);
    return data.data;
  },
});

export const getFakeOrderFx = createEffect({
  handler: async (id) => {
    const { data } = await QUERIES.getFakeOrder(id);
    return data.data;
  },
});

export const changeDetailsCheckoutAs = createEvent();
export const changeDetailsPaymentMethod = createEvent();
export const changeDetailsUserType = createEvent();
export const changeDetailsUserData = createEvent();
export const clearDetailsStorage = createEvent();
export const changeDetailsInsurance = createEvent();
export const changeDetails = createEvent();

export const changeAppliedPromoCode = createEvent();
export const appliedPromoCode$ = restore(changeAppliedPromoCode, null);
export const changePromoCodeMaxDiscount = createEvent();
export const promoCodeMaxDiscount$ = restore(changePromoCodeMaxDiscount, null);

export const changePromoCode = createEvent();

const promoCodeLocalStorage =
  getObject(`promoCode_${REACT_APP_PREFIX}`) || null;

export const promoCode$ = createStore(promoCodeLocalStorage)
  .on(changePromoCode, (s, p) => {
    return p;
  })
  .on(changeDetailsUserType, (s, p) => {
    return null;
  });

persist({ store: promoCode$, key: `promoCode_${REACT_APP_PREFIX}` });

export const getTwoOrderIntentFx = createEffect({
  handler: async (address) => {
    const { data } = await CHECKOUT.createOrderIntent(address);
    return data.data;
  },
});

export const isLoadingTwoOrderIntent$ = getTwoOrderIntentFx.pending.map(
  (state) => !!state,
);

export const setOrderIntent = createEvent();

export const twoOrderIntent$ = createStore(null)
  .on(getTwoOrderIntentFx.doneData, (_, p) => {
    if (p?.approved) {
      return p;
    }

    return null;
  })
  .on(setOrderIntent, (_, p) => p)
  .on(changeDetailsUserType, (_, p) => {
    if (p !== 'company') {
      return null;
    }
  })
  .reset(resetCart);

const defaultDetails = {
  id: null,
  checkoutAs: null,
  email: null,
  name: null,
  phone: null,
  paymentMethod: null,
  type: null,
  company_name: null,
  company_id: null,
  insurance: null,
};

const detailsLocalStorage = getObject(`details_${REACT_APP_PREFIX}`) || {
  ...defaultDetails,
};

export const details$ = createStore({
  ...detailsLocalStorage,
})
  .on($userInfo.updates, (s, user) => {
    if (!user.id) {
      return { ...defaultDetails };
    }

    return {
      id: user.id,
      email: user.email,
      checkoutAs: 'oldUser',
      name: `${user.first_name || ''} ${user.last_name || ''}`,
      phone: user.address?.phone,
      paymentMethod: null,
      type: user.type,
      company_name: user.company_details?.business_name || null,
      vat_number: user.company_details?.vat_number || null,
      insurance: null,
      ...user,
    };
  })
  .on(changeDetails, (_, p) => {
    return { ...p };
  })
  .on(changeDetailsCheckoutAs, (s, p) => {
    return {
      ...s,
      checkoutAs: p.type,
      type: null,
      email: null,
      name: null,
      phone: null,
      company_name: null,
      vat_number: null,
      paymentMethod: null,
      ...(p.emptyExtraFields && { ...p.emptyExtraFields }),
    };
  })
  .on(changeDetailsPaymentMethod, (s, p) => {
    return {
      ...s,
      paymentMethod: p,
    };
  })
  .on(changeDetailsUserType, (s, p) => {
    return {
      ...s,
      type: p,
      company_name: null,
      company_id: null,
      paymentMethod: s.paymentMethod,
    };
  })
  .on(changeDetailsUserData, (s, p) => {
    const newDetails = {
      ...s,
      ...p,
    };

    return {
      ...newDetails,
      vat_number: newDetails.company_id || newDetails.vat_number,
    };
  })
  .on(changeDetailsInsurance, (s, p) => {
    return { ...s, insurance: p };
  })
  .on(twoOrderIntent$.updates, (s, p) => {
    if (!p && s.paymentMethod === 'two') {
      return {
        ...s,
        paymentMethod: null,
      };
    }
  })
  .on(clearDetailsStorage, (_) => {
    const user = $userInfo.getState();

    if (!user.id) {
      return { ...defaultDetails };
    }

    return {
      id: user.id,
      email: user.email,
      checkoutAs: 'oldUser',
      name: `${user.first_name || ''} ${user.last_name || ''}`,
      phone: user.address?.phone,
      paymentMethod: null,
      type: user.type,
      company_name: user.company_details?.business_name || null,
      vat_number: user.company_details?.vat_number || null,
      insurance: null,
      ...user,
    };
  });

persist({ store: details$, key: `details_${REACT_APP_PREFIX}` });

export const changeInputFocused = createEvent();

const DEFAULT_FOCUSED_VALUES = {
  email: false,
  name: false,
  phone: false,
  type: false,
  company_name: false,
};

export const focusedUserTypeInputStatus$ = createStore({
  ...DEFAULT_FOCUSED_VALUES,
})
  .on(changeInputFocused, (s, p) => {
    return { ...s, ...p };
  })
  .on(clearDetailsStorage, (_, p) => {
    return { ...DEFAULT_FOCUSED_VALUES };
  });

export const changeCheckoutComments = createEvent();
const upperStoresUpdate = merge([
  changeDetailsCheckoutAs,
  changeDeliveryMethod,
  changeDetailsUserType,
  changeDetailsUserData,
]);

export const checkoutComments$ = createStore(null)
  .on(changeCheckoutComments, (_, p) => {
    if (!p) {
      return p;
    }

    return p.trim();
  })
  .on(upperStoresUpdate, (_) => null);

persist({
  store: checkoutComments$,
  key: `checkoutComments_${REACT_APP_PREFIX}`,
});

export const changeCheckoutPurchaseField = createEvent();

export const checkoutPurchaseField$ = createStore(null)
  .on(changeCheckoutPurchaseField, (_, p) => {
    if (!p) {
      return p;
    }

    return p.trim();
  })
  .on(upperStoresUpdate, (_) => null);

persist({
  store: checkoutPurchaseField$,
  key: `checkoutPurchaseField_${REACT_APP_PREFIX}`,
});

const setIsNewProductAdded = createEvent();

//CART LENGTH
export const cartLength$ = cart$.map((currStoreValue, oldCartLength) => {
  const currCartLength = currStoreValue?.length;

  if (currCartLength > oldCartLength) {
    setIsNewProductAdded(true);

    setTimeout(() => {
      setIsNewProductAdded(false);
    }, 3000);
  }

  return currCartLength;
});

export const cartAllItemsLength$ = cart$.map((cart) =>
  cart.reduce((acc, el) => {
    acc =
      acc +
      1 +
      el.reqExtra.length +
      el.optionalExtra.length +
      el.optionalSaleExtra.length +
      (el.consumables?.length || 0);

    return acc;
  }, 0),
);

export const isNewProductAdded$ = createStore(false).on(
  setIsNewProductAdded,
  (_, p) => p,
);

export const setIsCartPopupVisible = createEvent();

export const isCartPopupVisible$ = createStore(false).on(
  setIsCartPopupVisible,
  (_, p) => p,
);

export const changeBillingAddress = createEvent();
export const setIsBillingAddressSameAsDelivery = createEvent();

export const changeTwoCompanyAddress = createEvent();

export const twoCompanyAddress$ = createStore(null)
  .on(changeTwoCompanyAddress, (_, p) => p)
  .on(changeDetailsUserType, (_, p) => {
    if (p !== 'company') {
      return null;
    }
  })
  .reset(resetCart);

export const billingAddress$ = createStore(null)
  .on(changeBillingAddress, (_, p) => {
    return p;
  })
  .on(twoCompanyAddress$.updates, (s, p) => {
    if (p) {
      return `${p.street_address}, ${p.city}, ${p.postal_code}`;
    }

    return null;
  })
  .on($isUserLoggedIn.updates, (s, p) => (p ? s : null))
  .reset(resetCart);

persist({
  store: billingAddress$,
  key: `checkoutBillingAddress_${REACT_APP_PREFIX}`,
});

export const isBillingAddressSameAsDelivery$ = createStore(true, {
  key: `billingAddressSameAsDelivery_${REACT_APP_PREFIX}`,
}).on(setIsBillingAddressSameAsDelivery, (_, p) => p);

persist({
  store: isBillingAddressSameAsDelivery$,
  key: `billingAddressSameAsDelivery_${REACT_APP_PREFIX}`,
});

export const isBillingAddressChangeable$ = combine(
  details$,
  twoCompanyAddress$,
  (details, twoCompanyAddress) =>
    details.type !== 'company' || !details.company_id || !twoCompanyAddress,
);

cartLength$.watch((value) => {
  if (!value) {
    changeAppliedPromoCode(null);
    changePromoCode(null);
    clearDeliveryStorage();
    clearDetailsStorage();
    changeCheckoutComments(null);
    changeCheckoutPurchaseField(null);
    setIsCartPopupVisible(false);
    changeBillingAddress(null);
    changeTwoCompanyAddress(null);
  }
});
