import React from 'react';
import { Lang } from 'lang';

import { Row, T8y } from 'ui';

import { ReactComponent as AmericanExpress } from 'assets/images/icon/AmericanExpress_sm.svg';
import { ReactComponent as Mastercard } from 'assets/images/icon/Mastercard_sm.svg';
import { ReactComponent as Visa } from 'assets/images/icon/Visa_sm.svg';
import { ReactComponent as EasyHireLogo } from 'assets/images/picture/rentuuLogoNewFooter.svg';

import style from './poweredBy.module.scss';

const PoweredBy = () => {
  const { footer } = Lang();

  return (
    <Row direction="column" smAlign="center" className={style.poweredByWrapper}>
      <T8y variant="t1v1" mb2 bold className={style.title}>
        {footer.thisWebsite}
      </T8y>
      <EasyHireLogo className={style.easyHireLogo} />
      <T8y color="midGray" mt2 className={style.easyHireText}>
        {footer.rentuuOperates}
      </T8y>
      <T8y variant="t1v1" bold className={style.securePayments}>
        {footer.securePayments}
      </T8y>
      <Row smJustify="center" noWrap fullWidth>
        <Mastercard className={style.cardIcon} />
        <Visa className={style.cardIcon} />
        <AmericanExpress className={style.cardIcon} />
      </Row>
    </Row>
  );
};

export default PoweredBy;
