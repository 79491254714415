import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { getDomain, REACT_APP_PREFIX, REACT_APP_PUBLIC_URL } from 'consts';
import { BrandingContext } from 'contexts';
import { useStore } from 'effector-react';
import { sendCheckoutEvent, sendGAEvent } from 'googleAnalytics';
import { handleLogout, useGetCommonCheckoutData } from 'hooks';
import { Lang } from 'lang';
import * as CR from 'models/cart';
import { territoryID$ } from 'models/common';
import { setOrderCompleted } from 'models/orders';
import { $isUserAdmin, $userInfo } from 'models/user';
import { $UTMData } from 'models/UTMData';
import { sendMsgToSlack } from 'utils/sendMsgToSlack';

import { history } from 'libs/history';
import { CHECKOUT, CRM } from 'api';

export const useGetOnSubmitCheckout = ({
  cardElements,
  stripe,
  subscription,
  setCardError,
  setTwoError,
  setIsSubmitSending,
  setIsWholeSectionDisabled,
  isQuote,
}) => {
  const { form, checkout } = Lang();

  const branding = useContext(BrandingContext);

  const details = useStore(CR.details$);
  const isUserAdmin = useStore($isUserAdmin);
  const userInfo = useStore($userInfo);
  const UTMData = useStore($UTMData);
  const territoryID = useStore(territoryID$);
  const twoCompanyAddress = useStore(CR.twoCompanyAddress$);
  const twoOrderIntent = useStore(CR.twoOrderIntent$);
  const { latestTotalPrice } = useStore(CR.latestTotalPriceInfo$);
  const checkoutPurchaseField = useStore(CR.checkoutPurchaseField$);

  const {
    address,
    method,
    warehouse: selectedWarehouse,
    addressByPostcode,
  } = useStore(CR.deliveryMethod$);
  const appliedPromoCode = useStore(CR.appliedPromoCode$);
  const cartFormattedData = useStore(CR.cartFormattedData$);
  const productsForGoogleAnalytics = useStore(CR.productsForGoogleAnalytics$);
  const checkoutComments = useStore(CR.checkoutComments$);

  const [order, setOrder] = useState(null);

  const getFormattedCheckoutData = useGetCommonCheckoutData({
    details,
    deliveryMethodData: {
      address,
      method,
      selectedWarehouse,
      addressByPostcode,
    },
    appliedPromoCode,
    cartFormattedData,
    subscription,
    isQuote,
  });

  const sendGoogleAnalytics = useCallback(
    (order) => {
      sendGAEvent(
        isQuote ? 'getquote_page_getquote_finish' : 'checkout_page_place_order',
        { order_id: order.order_id, territory_id: territoryID },
      );
      sendCheckoutEvent(
        'Purchase',
        4,
        productsForGoogleAnalytics,
        branding.currency,
      );
    },
    [branding.currency, isQuote, territoryID, productsForGoogleAnalytics],
  );

  const getCardIntent = useCallback(
    async (order) => {
      try {
        const { data } = await CHECKOUT.pay(order.order_hash);

        if (!stripe || !cardElements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const cardNumber = cardElements.getElement('cardNumber');

        return await stripe.confirmCardPayment(data.clientSecret, {
          payment_method: {
            card: cardNumber,
          },
          return_url: order.idVerification
            ? `${REACT_APP_PUBLIC_URL}/${PATHS._VERIFICATION}/?slug=${order.idVerification.slug}&link=${order.idVerification.link}&email=${order.user?.email}&orderId=${order.orderNumber}`
            : `${REACT_APP_PUBLIC_URL}/${PATHS._SUCCESS}/`,
        });
      } catch (e) {
        console.warn(e);
        setCardError({ isError: true, message: null });
        setIsSubmitSending(false);
        setIsWholeSectionDisabled(true);

        sendMsgToSlack(
          `/pay request failed: ${
            e.response.data?.message || e.response.status
          }`,
          true,
          details.email,
        );
      }
    },
    [
      stripe,
      cardElements,
      setCardError,
      setIsSubmitSending,
      setIsWholeSectionDisabled,
      details.email,
    ],
  );

  const getAdminPath = useCallback(() => {
    try {
      if (!userInfo) {
        return '';
      }

      const userId = userInfo.id;

      const adminDomain = getDomain();
      let adminPath = `admin.${adminDomain}`;

      if (REACT_APP_PUBLIC_URL.includes('stage.')) {
        adminPath = `admin-stage.${adminDomain}`;
      }

      if (REACT_APP_PUBLIC_URL.includes('dev.')) {
        adminPath = `admin-dev.${adminDomain}`;
      }

      return `https://${adminPath}/${REACT_APP_PREFIX}/users/${userId}/orders`;
    } catch (e) {
      return PATHS.ROOT;
    }
  }, [userInfo]);

  const completeOrder = useCallback(
    (order) => {
      setOrderCompleted({
        userPromo: order.userPromo || null,
        user: order.user,
        order_hash: order.order_hash,
        email: order.user?.email,
        insuranceType: order.insuranceType,
        subtotal: order.subtotal,
        orderNumber: order.orderNumber,
        idVerification: order.idVerification,
        isQuote,
      });

      CR.resetCart();

      if (isUserAdmin) {
        const adminPath = getAdminPath();

        handleLogout();
        window.location.href = adminPath;
      } else {
        history.replace({
          pathname:
            PATHS[order.idVerification?.link ? 'VERIFICATION' : 'SUCCESS'],
          state: {
            from: 'product',
          },
        });
      }
    },
    [getAdminPath, isUserAdmin, isQuote],
  );

  const payForOrder = useCallback(
    async (order) => {
      const result = await getCardIntent(order);
      const isError = !!result.error;

      setCardError({
        isError,
        message: result.error?.decline_code || result.error?.code,
      });
      setIsWholeSectionDisabled(isError);

      if (!isError) {
        completeOrder(order);
      } else {
        if (latestTotalPrice?.grand_total > 500) {
          CRM.sendTask({
            task: {
              title: 'Online payment failed',
              description: `The client unsuccessfully tried to pay online with his card. 
                          His order amount is greater than 500${branding.currencySymbol}. 
                          Please check the failed reason in Stripe and call this customer to inform him accordingly`,
              email: details.email,
              phone: details.phone,
              targetable_type: 'Contact',
            },
            territoryId: territoryID,
          });
        }

        sendMsgToSlack(
          `Message: ${result.error?.message || ''}; type: ${
            result.error?.type
          }; orderId: ${order.order_hash}`,
          true,
          details.email,
        );
      }
    },
    [
      getCardIntent,
      setCardError,
      setIsWholeSectionDisabled,
      completeOrder,
      latestTotalPrice,
      details.email,
      details.phone,
      branding.currencySymbol,
      territoryID,
    ],
  );

  const payForOrderWithTwo = useCallback(
    async (order, checkoutOrderData) => {
      try {
        const { data } = await CHECKOUT.payTwo(order.order_hash, {
          ...checkoutOrderData,
        });

        if (!data.error) {
          sendGAEvent('two_verification_redirect');
          CR.resetCart();
          window.open(data.payment_url, '_self');
        } else {
          throw new Error(data.error);
        }
      } catch (e) {
        console.warn(e);
        sendGAEvent('two_payment_method_failed_redirect');
        setTwoError(
          'Your payment unsuccessful, please continue with credit card ',
        );
        setIsSubmitSending(false);
        setIsWholeSectionDisabled(true);
      }
    },
    [setTwoError, setIsSubmitSending, setIsWholeSectionDisabled],
  );

  return useCallback(async () => {
    const isPaymentCard = details.paymentMethod === 'card';

    try {
      setIsSubmitSending(true);

      if (order && !isQuote) {
        await payForOrder(order);
        return;
      }

      const checkoutData = getFormattedCheckoutData();

      const { data } = await CHECKOUT.go({
        ...checkoutData,
        payment_method: isQuote ? undefined : checkoutData.payment_method,
        referenceNumber: isQuote ? undefined : checkoutPurchaseField,
        comment: checkoutComments,
        ...(UTMData && { utm: { ...UTMData } }),
        isQuote,
      });

      const createdOrder = data?.data || {};
      setOrder(createdOrder);

      sendGoogleAnalytics(createdOrder);

      if (isQuote) {
        completeOrder(createdOrder);
        return;
      }

      if (isPaymentCard) {
        await payForOrder(createdOrder);
        return;
      }

      if (details.paymentMethod === 'two') {
        await payForOrderWithTwo(createdOrder, {
          ...checkoutData,
          user: {
            ...checkoutData.user,
            billingAddress:
              checkoutData.user.billingAddress ||
              `${twoCompanyAddress.street_address}, ${twoCompanyAddress.city}, ${twoCompanyAddress.postal_code}`,
          },
          payment_method: isQuote ? undefined : checkoutData.payment_method,
          comment: checkoutComments,
          ...(UTMData && { utm: { ...UTMData } }),
          two: {
            trackingId: twoOrderIntent?.tracking_id,
            billing: {
              postcode: twoCompanyAddress?.postal_code,
              posttown: twoCompanyAddress?.city,
            },
            delivery:
              checkoutData.delivery_method === 'warehouse'
                ? undefined
                : {
                    postcode: address.postcode,
                    posttown: addressByPostcode?.town,
                  },
          },
          isQuote,
        });
        return;
      }

      completeOrder(createdOrder);
    } catch (e) {
      sendGAEvent('error-something-went-wrong-checkout');
      switch (e.response?.data?.slug) {
        case 'deliveryFromWarehouseNotAvailable':
        case 'deliveryNotAvailable':
          toast.error(form.errors.deliveryFromWarehouseNotAvailable);
          break;
        case 'promoAlreadyRedeemed':
          toast.error(checkout.promo.promoAlreadyRedeemed);
          break;
        default:
          toast.error(form.errors.wentWrong);
      }
    } finally {
      setIsSubmitSending(false);
    }
  }, [
    details.paymentMethod,
    setIsSubmitSending,
    order,
    isQuote,
    getFormattedCheckoutData,
    checkoutPurchaseField,
    checkoutComments,
    UTMData,
    sendGoogleAnalytics,
    completeOrder,
    payForOrder,
    payForOrderWithTwo,
    twoCompanyAddress,
    twoOrderIntent,
    address.postcode,
    addressByPostcode,
    form.errors,
    checkout.promo.promoAlreadyRedeemed,
  ]);
};
