import { isETH } from 'consts';

export const getFormattedPricesForTable = ({
  sortedPrices,
  additionalPrices,
  showInfinitySignInPrices,
}) => {
  const newSortedPrices = sortedPrices.map((el, index) => {
    const isLastRange = index === sortedPrices.length - 1;
    const isIdentical = el.period_from === el.period_to;
    const priceRow = `${el.period_from}-${
      showInfinitySignInPrices && isLastRange ? '∞' : el.period_to
    }`;

    return {
      ...el,
      daysCount: isIdentical ? el.period_to : priceRow,
      isLastRange,
      isIdentical,
      priceRow,
    };
  });

  return additionalPrices
    ? [
        ...newSortedPrices,
        {
          ...additionalPrices,
          daysCount: additionalPrices.after_days,
          oldPrice: additionalPrices.old_price_per_day,
          price: additionalPrices.price_per_day,
          isIdentical: false,
          isAdditional: true,
        },
      ]
    : newSortedPrices;
};

export const getMainPriceForCard = ({
  product,
  hiringPeriod,
  isProductPage,
}) => {
  if (!product?.prices || !product.prices?.length) {
    return { price: null };
  }

  // if (isETH) {
  //   let oneRange = product.prices.find(el => el.period_from === 1);
  //
  //   if (product.displayedRange === 'week') {
  //     oneRange = product.prices.find(el => el.period_to === 7);
  //   }
  //
  //   return oneRange || { price: 0 };
  // }
  if (isETH) {
    let oneRange;

    if (product.displayedRange === 'week') {
      oneRange = product.prices.find((el) => el.period_to === 7);
    } else {
      const prices = isProductPage
        ? product.prices.filter(
            (priceRange, index) =>
              index === product.prices.length - 1 ||
              priceRange.period_to >= product.min_period_days,
          )
        : product.prices;

      const pricesWithDaily = prices.map((el) => ({
        ...el,
        dayPrice: Number(el.price / el.period_to),
      }));
      const minRange = Math.min(...pricesWithDaily.map((el) => el.dayPrice));
      oneRange = pricesWithDaily.find((el) => el.dayPrice === minRange);

      if (oneRange) {
        oneRange.price = Number(oneRange.price / oneRange.period_to);
        oneRange.oldPrice = Number(oneRange.oldPrice / oneRange.period_to);
      }
    }

    return oneRange || { price: 0 };
  }

  let price = { price: 0 };

  if (hiringPeriod) {
    product.prices.forEach((el) => {
      if (hiringPeriod >= el.period_from && hiringPeriod <= el.period_to) {
        price = el;
        return true;
      } else if (hiringPeriod > el.period_to) {
        price = el;
      }
    });
  }

  if (!price.price) {
    const minRange = Math.min(
      ...product.prices.map((el) =>
        el.period_from ? Number(el.period_from) : 0,
      ),
    );
    price = minRange
      ? product.prices.find((el) => el.period_from === minRange)
      : { price: 0 };
  }

  return price;
};

export const getPriceRangeTextInTable = (el, products, extendedRangesPrice) => {
  if (extendedRangesPrice && el.isAdditional) {
    return products.extraDay;
  }

  return `${el.daysCount}${el.isAdditional ? '+' : ''} ${
    products[el.isIdentical && el.period_from === 1 ? 'day' : 'days']
  }`;
};
