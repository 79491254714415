import React, { useMemo, useRef } from 'react';
import CookieBanner from 'react-cookie-banner';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import { isETH } from 'consts';
import { useStore } from 'effector-react';
import {
  useGetLayoutConfiguration,
  useGetStaticHeaderHeight,
  useScrollToTopOnPathChanges,
  useSendUserAttributesToHotjar,
} from 'hooks';
import { Lang } from 'lang';
import { isCategory$ } from 'models/isCategory';
import { $isUserAdmin } from 'models/user';

import Carousel from 'features/common/Carousel';
import RecentlyViewedProducts from 'features/common/RecentlyViewedProducts';

import Meta from '../seo';
import ContextProviders from './ContextProviders';
import { Footer } from './Footer/Footer';
import Header from './Header/Header';
import HiddenLayoutImages from './HiddenLayoutImages';
import { useGetUserIfTokenPresents } from './useGetUserIfTokenPresents';

import 'react-toastify/dist/ReactToastify.css';
import style from './mainLayout.module.scss';

toast.configure({
  autoClose: 3000,
  position: 'bottom-right',
});

const MainLayout = ({ children }) => {
  const { header } = Lang();

  const location = useLocation();
  const {
    isFooter,
    isProfile,
    isCarousel,
    isRecentlyViewedProducts,
    isHeader,
  } = useGetLayoutConfiguration();

  useGetUserIfTokenPresents();
  useScrollToTopOnPathChanges();
  useSendUserAttributesToHotjar();
  const offsetFromTop = useGetStaticHeaderHeight();

  const mainContentRef = useRef(null);

  const isUserAdmin = useStore($isUserAdmin);
  const isCategory = useStore(isCategory$);

  const isMobileSearchPage = useMemo(
    () => location.pathname.includes(PATHS.MOBILE_SEARCH),
    [location.pathname],
  );

  return (
    <div className={clsx(style.page, isUserAdmin && style.adminWrapper)}>
      <div className={isUserAdmin ? style.contentWrapper : undefined}>
        <Meta isAddHireWord />
        <ContextProviders>
          {!isETH && !isUserAdmin && isHeader && (
            <CookieBanner
              styles={{
                banner: { backgroundColor: '#222222', height: 50 },
                message: { lineHeight: 'normal' },
                button: {
                  backgroundColor: '#FF6600',
                  color: '#FFF',
                  position: 'relative',
                  height: 30,
                  marginTop: 0,
                  right: 0,
                  top: 0,
                  marginLeft: 10,
                },
              }}
              message={header.byUsingThis}
              buttonMessage={header.cookieBtn}
              cookie="user-has-accepted-cookies"
              className={style.bannerWrapper}
            />
          )}
          {isHeader && (
            <>
              <Header mainContentRef={mainContentRef} />
              <HiddenLayoutImages />
            </>
          )}
          <main
            className={clsx(style.main, isProfile && style.backgroundProfile)}
            id="mainContent"
            style={
              !isMobileSearchPage
                ? {
                    minHeight: `calc(100vh - ${offsetFromTop}px)`,
                  }
                : undefined
            }
          >
            {children}
          </main>
          {isCarousel && !isCategory.slug && <Carousel />}
          {isRecentlyViewedProducts && <RecentlyViewedProducts />}
          {isFooter && <Footer />}
        </ContextProviders>
      </div>
    </div>
  );
};

export default MainLayout;
