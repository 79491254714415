import { categoryDefaultImageSrc, getInitialLang } from 'consts';
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
} from 'effector';
import { createGate } from 'effector-react';
import { getCategoriesWithChildren } from 'utils/categories';
import { getFlatCategoriesList } from 'utils/formatArrays';
import { sortByAlphabet } from 'utils/formatStrings';
import { getProductWithActualPrices } from 'utils/getProductWithActualPrices';
import { getTranslatedFieldValue } from 'utils/getTranslatedFieldValue';

import { branding$ } from '../branding';
import { isCategory$ } from '../isCategory';
import { currentLang$ } from '../language';
import { settings$ } from '../settings';
import { $userInfo } from '../user';

export const categoriesGate = createGate('');
export const algoliaCategoriesGate = createGate('');

export const getAllCategoriesFx = createEffect();
export const getAllCategoriesFromAlgoliaFx = createEffect();

export const isCategoriesRequestFinished$ = createStore(false)
  .on(getAllCategoriesFx.done, (_) => true)
  .on(getAllCategoriesFromAlgoliaFx.done, (_) => true);

export const getPopularProducts = createEvent();
export const getPopularProductsFx = createEffect();

export const isPopularProductsLoading$ = getPopularProductsFx.pending.map(
  (state) => !state,
);

export const popularProducts$ = restore(getPopularProductsFx.doneData, []);

export const actualPopularProducts$ = combine(
  popularProducts$,
  $userInfo,
  (products, user) => {
    if (!products?.length) {
      return [];
    }

    const userDiscount = user.tier?.discount;

    if (!userDiscount) {
      return products;
    }

    return products.map((product) =>
      getProductWithActualPrices(product, userDiscount),
    );
  },
);

export const popularProductsLocalized$ = combine(
  actualPopularProducts$,
  currentLang$,
  settings$,
  (products, lang, { defaultLanguage }) => {
    if (!products) {
      return [];
    }

    return products.map((el) => ({
      ...el,
      name: el.names ? el.names[lang] || el.names[defaultLanguage] : '',
    }));
  },
);

export const isPopularProductsRequestFinished$ = createStore(false).on(
  getPopularProductsFx.done,
  (_) => true,
);

const initialCategories$ = createStore([])
  .on(getAllCategoriesFx.doneData, (_, p) => p)
  .on(getAllCategoriesFromAlgoliaFx.doneData, (s, p) => {
    //don't set algolia result if we have result from BE
    if (s.length) {
      return;
    }

    return p;
  });

const initialCategoriesLocalized$ = combine(
  initialCategories$,
  currentLang$,
  settings$,
  branding$,
  (categories, lang, { defaultLanguage }, { defaultImageCategory }) => {
    return categories.map((el) => ({
      ...el,
      name: el.names[lang] || el.names[defaultLanguage],
      seo_h1: el.seo_h1?.[lang] || el.seo_h1?.[defaultLanguage],
      description: el.descriptions[lang] || el.descriptions[defaultLanguage],
      src: el.image?.medium || defaultImageCategory || categoryDefaultImageSrc,
      webpImage: el.image?.webp?.medium,
      generalImage:
        el.image?.medium || defaultImageCategory || categoryDefaultImageSrc,
    }));
  },
);

export const categories$ = createStore([]).on(
  initialCategoriesLocalized$.updates,
  (s, categories) => {
    return getCategoriesWithChildren(categories);
  },
);

const categoriesWithoutHidden$ = createStore([]).on(
  initialCategoriesLocalized$.updates,
  (_, categories) => {
    const filteredCategories = categories.filter((el) => !el.hidden);
    return getCategoriesWithChildren(filteredCategories);
  },
);

export const flatCategoriesListLocalized$ = createStore([]).on(
  categories$.updates,
  (s, categories) => {
    return getFlatCategoriesList(categories, 'childs');
  },
);

export const isCategoriesLoading$ = getAllCategoriesFromAlgoliaFx.pending.map(
  (state) => !!state,
);

export const categoriesLocalized$ = createStore([]).on(
  categoriesWithoutHidden$.updates,
  (_, categories) => {
    return sortByAlphabet(categories);
  },
);

export const setCurrentCategory = createEvent();
export const clearCurrentCategory = createEvent();

export const currentCategory$ = createStore(null)
  .on(setCurrentCategory, (_, p) => p)
  .on(clearCurrentCategory, (_) => null);

export const childs$ = currentCategory$.map(
  (store) => store?.childs?.filter((el) => !el.hidden) || null,
);

export const currentCategoryParent$ = combine(
  currentCategory$,
  flatCategoriesListLocalized$,
  (currentCategory, categories) => {
    if (currentCategory?.parent_id) {
      return (
        categories.find((el) => el.id === currentCategory.parent_id) || null
      );
    }

    return null;
  },
);

export const currentCategoryGrandParent$ = combine(
  flatCategoriesListLocalized$,
  currentCategoryParent$,
  (categories, parent) => {
    const grandChildId = parent?.parent_id;

    return categories.find((el) => el.id === grandChildId) || null;
  },
);

export const setIsProductsPerCategoryFinished = createEvent();

export const isProductsPerCategoryFinished$ = createStore(false).on(
  setIsProductsPerCategoryFinished,
  (_, p) => p,
);

export const getCurrProduct = createEvent();
export const getCurrProductFx = createEffect();
export const clearProductData = createEvent();
export const setIsCurrProductLoading = createEvent();

export const isIdleCurrProduct$ = createStore(true)
  .on(getCurrProductFx.finally, () => false)
  .on(setIsCurrProductLoading, (_, p) => p);

export const currProduct$ = restore(getCurrProductFx.doneData, null).on(
  clearProductData,
  (_) => null,
);

export const actualCurrProductData$ = combine(
  currProduct$,
  $userInfo,
  (product, user) => {
    if (!product) {
      return null;
    }

    const userDiscount = user.tier?.discount;

    if (!userDiscount) {
      return product;
    }

    return getProductWithActualPrices(product, userDiscount);
  },
);

export const currProductLocalized$ = combine(
  currProduct$,
  currentLang$,
  settings$,
  (data, lang, { defaultLanguage }) => {
    if (!data) {
      return null;
    }

    const staticDefaultLanguage = getInitialLang();
    let currCategory = data?.category;

    if (currCategory) {
      currCategory = {
        ...currCategory,
        name:
          currCategory.names?.[lang] ||
          currCategory.names?.[defaultLanguage] ||
          currCategory.names?.[staticDefaultLanguage],
      };
    }

    return {
      ...data,
      category: currCategory,
      name:
        data.names[lang] ||
        data.names[defaultLanguage] ||
        data.names[staticDefaultLanguage],
      safetyRecommendations: data.safetyRecommendations.map((el) => ({
        ...el,
        name: getTranslatedFieldValue(el.names, lang, defaultLanguage),
        description: getTranslatedFieldValue(
          el.descriptions,
          lang,
          defaultLanguage,
        ),
      })),
      description: getTranslatedFieldValue(
        data.descriptions,
        lang,
        defaultLanguage,
      ),
    };
  },
);

export const isCurrProductRequestFinished$ = createStore(false)
  .on(getCurrProductFx.done, (_) => true)
  .on(clearProductData, (_) => false);

export const getProductVariations = createEvent();
export const getProductVariationsFx = createEffect();

export const productVariations$ = restore(getProductVariationsFx.doneData, []);
export const isProductVariationsRequestFinished$ = createStore(false).on(
  getProductVariationsFx.done,
  (_) => true,
);

export const getProductHiredTogether = createEvent();
export const getProductHiredTogetherFx = createEffect();

const productHiredTogether$ = restore(getProductHiredTogetherFx.doneData, []);

export const productHiredTogetherLocalized$ = combine(
  productHiredTogether$,
  currentLang$,
  settings$,
  (data, lang, { defaultLanguage }) => {
    if (!data) {
      return null;
    }
    const staticDefaultLanguage = getInitialLang();

    return data.map((el) => ({
      ...el,
      name: el.names
        ? el.names[lang] ||
          el.names[defaultLanguage] ||
          el.names[staticDefaultLanguage]
        : '',
    }));
  },
);

export const isProductHiredTogetherRequestFinished$ = createStore(false).on(
  getProductVariationsFx.done,
  (_) => true,
);

export const isIdleProductHiredTogether$ = getProductVariationsFx.pending.map(
  (state) => !!state,
);

export const fetchGetCurrProductFxError$ = restore(
  getCurrProductFx.failData,
  null,
).on(clearProductData, (_) => null);

export const getRelatedJobTypesFx = createEffect();

export const relatedJobTypes$ = restore(getRelatedJobTypesFx.doneData, []).on(
  clearProductData,
  (_) => null,
);

export const toggleShowPricesFor = createEvent();

export const showPricesFor$ = createStore(0).on(
  toggleShowPricesFor,
  (_, p) => p,
);

export const setIsOpenTradeCustomerQuoteModal = createEvent();

export const isOpenTradeCustomerQuoteModal$ = createStore(false).on(
  setIsOpenTradeCustomerQuoteModal,
  (_, p) => p,
);
