import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PATHS } from 'AppPaths';
import clsx from 'clsx';
import {
  LAPTOP_SCREEN,
  REACT_APP_API_HIDE_PRODUCT_CTA,
  SMALL_SCREEN,
} from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import AdminBar from 'layout/AdminBar';
import { Bar } from 'layout/Bar/Bar';
import CartSingWrapper from 'layout/Header/CartSignWrapper';
import Message from 'layout/Header/Message';
import MobileNav from 'layout/Header/MobileNav';
import { setIsMenuOpen } from 'layout/Header/model';
import Nav from 'layout/Header/Nav';
import MobileSearchInput from 'layout/Header/Nav/MobileSearchInput';
import Phone from 'layout/Header/Phone';
import ProfileSing from 'layout/Header/ProfileSing';
import { SelectLanguage } from 'layout/Header/SelectLanguage/SelectLanguage';
import { isCategory$ } from 'models/isCategory';
import { $isUserAdmin, $isUserLoggedIn } from 'models/user';
import { getQueryParam } from 'utils/formatStrings';

import { Search } from 'features/search';
import AlgoliaAutocompleteSearchWrapper from 'features/search/AlgoliaAutocompleteSearchWrapper';

import { useMatchMedia } from 'libs/useMatchMedia';

import Banner from '../Banner';

import { Row } from 'ui';
import BreadCrumbs from 'ui/BreadCrumbs';

import style from './commonHeader.module.scss';

const CommonHeader = ({ isMenuOpen }) => {
  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const isUserLoggedIn = useStore($isUserLoggedIn);
  const isUserAdmin = useStore($isUserAdmin);
  const isCategory = useStore(isCategory$);

  const location = useLocation();

  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const [openId, setOpenId] = useState(0);
  const [isSmallHeader, setIsSmallHeader] = useState(false);

  const isShowCarbonEmissionBar = location.pathname !== PATHS.CARBON_EMISSION;

  const close = useCallback(() => setIsMenuOpen(false), []);

  const isShowCartSing = useMemo(() => {
    if (settings.productCTAAction?.type) {
      return (
        settings.productCTAAction.type !== 'make enquiry focus' ||
        isUserLoggedIn
      );
    }
    return false;
  }, [settings, isUserLoggedIn]);

  const mode = useMemo(() => {
    return getQueryParam(location.search, 'mode');
  }, [location.search]);

  const isEmptyHeader = useMemo(() => {
    return (
      mode === 'checkout' ||
      location.pathname.includes(PATHS.RESERVATION_CHECKOUT) ||
      location.pathname.includes(PATHS.RESERVATION_QUOTE)
    );
  }, [location, mode]);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 70) {
      setIsSmallHeader(true);
      return;
    }
    if (window.scrollY < 10) {
      setTimeout(() => setIsSmallHeader(false));
    }
  }, []);

  useEffect(() => {
    if (isSmallScreen && !isEmptyHeader) {
      document.addEventListener('scroll', handleScroll);
      return () => {
        document.removeEventListener('scroll', handleScroll);
        setIsSmallHeader(false);
      };
    }
  }, [isSmallScreen, isEmptyHeader, handleScroll]);

  const isShowBreadCrumbs = !isEmptyHeader && (!isMenuOpen || !isLaptop);
  const isProductPage =
    !isCategory.isCategory && isCategory.isProductCategoryPage;

  const isHideMessage =
    isCategory.isCategoryLoading ||
    (isProductPage && REACT_APP_API_HIDE_PRODUCT_CTA) ||
    isEmptyHeader;

  return (
    <>
      {isUserAdmin && <AdminBar />}
      {!isLaptop && settings.showCustomizeInventory && <Bar />}
      <div className={style.headerNav}>
        <Row
          justify="stretch"
          align="center"
          fullWidth
          noWrap
          className={style.headerWrapper}
        >
          {isLaptop && (
            <MobileNav
              openId={openId}
              setOpenId={setOpenId}
              close={close}
              isMenuOpen={isMenuOpen}
              isShowCartSing={isShowCartSing}
            />
          )}
          <Row
            align="center"
            smJustify="center"
            smFullWidth
            noWrap
            className={clsx(
              style.navWrapper,
              isSmallHeader && style.smallHeader,
            )}
          >
            {isSmallHeader && isSmallScreen && !isEmptyHeader && (
              <MobileSearchInput small />
            )}
            {!isSmallHeader && (
              <Link
                to={PATHS.ROOT}
                className={style.logoWrapper}
                aria-label={branding.companyName}
              >
                {!isSmallScreen && branding.logo && (
                  <img
                    src={branding.logo}
                    className={style.logo}
                    alt={branding.companyName}
                  />
                )}
                {isSmallScreen && branding.headerMobileLogo && (
                  <img
                    src={branding.headerMobileLogo}
                    className={style.mobileLogo}
                    alt={branding.companyName}
                  />
                )}
              </Link>
            )}
            {!isLaptop && !isEmptyHeader && <Nav />}
          </Row>
          {!isSmallScreen && !isEmptyHeader && (
            <AlgoliaAutocompleteSearchWrapper>
              <Search />
            </AlgoliaAutocompleteSearchWrapper>
          )}
          <Row
            noWrap
            align="center"
            className={clsx(
              style.iconsWrapper,
              style.basketAndLang,
              REACT_APP_API_HIDE_PRODUCT_CTA && !branding.showPhoneIconInHeader
                ? style.basketAndLangSmallAmount
                : style.basketAndLangBigAmount,
            )}
          >
            {branding.companyPhoneNumber && branding.showPhoneIconInHeader && (
              <Phone />
            )}
            {!isLaptop && !isHideMessage && <Message />}
            {!isEmptyHeader && <ProfileSing />}
            {isShowCartSing && !isEmptyHeader && (
              <CartSingWrapper close={close} />
            )}
            <Row mdHide pointer>
              <SelectLanguage />
            </Row>
          </Row>
        </Row>
      </div>
      {!isSmallHeader && isSmallScreen && !isEmptyHeader && (
        <MobileSearchInput />
      )}
      <Banner isShowCarbonEmissionBar={isShowCarbonEmissionBar} />
      {isShowBreadCrumbs && <BreadCrumbs />}
    </>
  );
};

export default CommonHeader;
