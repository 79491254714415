import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { isETH } from 'consts';
import { BrandingContext } from 'contexts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { $userInfo } from 'models/user';

import { Row, T8y } from 'ui';

import style from './mainProductPrice.module.scss';

const MainProductPrice = ({
  mainPrice = {},
  showDiscount,
  isBigCard,
  defaultHiringPeriodProduct,
}) => {
  const { products } = Lang();

  const branding = useContext(BrandingContext);

  const user = useStore($userInfo);

  const pricePerText = useMemo(() => {
    if (!isETH) {
      return products.other.perDay;
    }

    return defaultHiringPeriodProduct === 7
      ? products.pricePerWeek
      : products.other.perDay;
  }, [
    defaultHiringPeriodProduct,
    products.other.perDay,
    products.pricePerWeek,
  ]);

  return (
    <div className={style.priceWrapper}>
      {isETH && !!mainPrice.price && defaultHiringPeriodProduct !== 7 && (
        <T8y variant={isBigCard ? 't2v1' : 't4'} as="span" bold>
          Price starting from (Excl. VAT)
        </T8y>
      )}
      {showDiscount && !!mainPrice.price && (
        <Row className={style.oldPriceWrapper} noWrap>
          <T8y
            as="span"
            variant="t2v1"
            className={clsx(
              style.oldProductPriceText,
              !isBigCard &&
                style.smallProductCardText &&
                style.smallProductCardPrice,
            )}
            inline
            color="midGray"
          >
            {products.wasDiscount}&nbsp;
          </T8y>
          <T8y
            as="span"
            variant="t2v1"
            className={clsx(
              style.oldProductPrice,
              !isBigCard &&
                style.smallProductCardText &&
                style.smallProductCardPrice,
            )}
            inline
            color="midGray"
          >
            {branding.currencySymbol + mainPrice.oldPrice.toFixed(2)}
          </T8y>
        </Row>
      )}
      <div className={style.productPriceWrapper}>
        {showDiscount && !!mainPrice.price && (
          <T8y
            as="span"
            className={clsx(
              style.productDiscountInPrice,
              isBigCard ? style.productPriceBig : style.productPriceSmall,
            )}
            bold
          >
            -{user.userDiscount}%
          </T8y>
        )}
        {!!mainPrice.price ? (
          <T8y
            as="span"
            className={
              isBigCard ? style.productPriceBig : style.productPriceSmall
            }
            bold
          >
            {branding.currencySymbol + mainPrice.price.toFixed(2)}
          </T8y>
        ) : (
          <T8y
            as="span"
            className={
              isBigCard ? style.productPriceBig : style.productPriceSmall
            }
            bold
          >
            {products.noPrices.title}
          </T8y>
        )}
        {!!mainPrice.price && (
          <T8y
            as="span"
            variant={isBigCard ? 't2v1' : 't4'}
            className={clsx(
              style.afterExistPriceText,
              !isBigCard && style.smallProductCardText,
            )}
          >
            {pricePerText}
          </T8y>
        )}
      </div>
    </div>
  );
};

export default MainProductPrice;
