import { ALGOLIA_SEARCH_INDEX, DEFAULT_COMPANY_TAG } from 'consts';
import { getUrlSearchAlgoliaParams } from 'utils/formatUrl';

export function capitalizeFirstLetter(str) {
  if (!str) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeFirstLetterAllWords(str) {
  if (!str) {
    return '';
  }

  return str
    .split(' ')
    .map((el) => {
      if (el.length > 1) {
        return el.charAt(0).toUpperCase() + el.slice(1);
      }
      return el;
    })
    .join(' ');
}

export const getSeoAltTags = ({ product = {} }) => {
  const title = (product && product.name) || '';

  if (!title) {
    return null;
  }

  const formattedTitle = title.toString().split('-')[0].trim();

  return `${formattedTitle} ${DEFAULT_COMPANY_TAG}`;
};

export const removeTags = (string) => {
  if (!string) {
    return null;
  }
  return string.replace(/(<([^>]+)>)/gi, '');
};

export const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    return document.execCommand('copy');
  } catch (err) {
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
};

export const getQueryParam = (search, key) => {
  return new URLSearchParams(search).get(key);
};

export const stripHtml = (html) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const sortByAlphabet = (arr) => {
  return [...arr].sort((a, b) => a.name?.localeCompare(b?.name));
};

export const getFirstIndexName = () => {
  const initialParams = getUrlSearchAlgoliaParams() || {};

  if (!initialParams.sort && !initialParams.order) {
    return ALGOLIA_SEARCH_INDEX;
  }

  return `${ALGOLIA_SEARCH_INDEX}_${initialParams.sort || 'popular'}_${
    initialParams.order || 'desc'
  }`;
};
