import { useContext, useMemo } from 'react';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { currentLang$ } from 'models/language';
import { $isUserLoggedIn, $userInfo } from 'models/user';

export const useModifyHitsFromAlgolia = (hits) => {
  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const currentLangState = useStore(currentLang$);
  const isUserLoggedIn = useStore($isUserLoggedIn);
  const user = useStore($userInfo);

  const discount = useMemo(() => {
    return user.tier?.discount || 0;
  }, [user]);

  return useMemo(() => {
    if (!hits.length) {
      return [];
    }

    let localizedHits = hits.map((el) => ({
      ...el,
      name: el.names[currentLangState] || el.names[settings.defaultLanguage],
      description:
        el.descriptions?.[currentLangState] ||
        el.descriptions?.[settings.defaultLanguage],
      image: el.image || branding.defaultImageProduct,
    }));

    if (isUserLoggedIn && discount) {
      localizedHits = localizedHits.map((el) => ({
        ...el,
        prices: el.prices?.map((range) => ({
          ...range,
          oldPrice: range.price,
          price: range.price - range.price * (discount / 100),
        })),
        additional_prices: el.additional_prices && {
          ...el.additional_prices,
          old_price_per_day: el.additional_prices.price_per_day,
          price_per_day:
            el.additional_prices.price_per_day -
            el.additional_prices.price_per_day * (discount / 100),
        },
      }));
    }

    return localizedHits;
  }, [branding, currentLangState, discount, hits, isUserLoggedIn, settings]);
};
