import React from 'react';
import { Button, Checkbox as CheckboxR, useCheckboxState } from 'reakit';
import clsx from 'clsx';

import { Row, T8y } from 'ui';

import style from './checkbox.module.scss';

export const Checkbox = React.forwardRef(
  (
    {
      label,
      checked,
      onChange,
      policy,
      size = 'medium',
      color = 'dark',
      labelClassName,
      isCheckout,
      isDisabled,
      ...rest
    },
    ref,
  ) => {
    const checkbox = useCheckboxState({ state: checked });

    return (
      <CheckboxR
        as={Button}
        checked={checked}
        onChange={isDisabled ? undefined : onChange}
        ref={ref}
        {...checkbox}
        {...rest}
      >
        <Row align="start" className={policy && style.policy} noWrap>
          <div
            className={clsx(
              style.checkbox,
              size === 'small' && style.small,
              size === 'medium' && style.medium,
              color === 'dark' && style.dark,
              color === 'light' && style.light,
              isCheckout && style.checkout,
              isDisabled && style.disabled,
            )}
          />
          <T8y
            as="span"
            color={color === 'light' ? 'light' : 'secondary'}
            className={clsx(style.textAlign, labelClassName)}
            ml
            pointer
          >
            {label}
          </T8y>
        </Row>
      </CheckboxR>
    );
  },
);
