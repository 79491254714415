import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { isETH, SMALL_SCREEN } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import { Lang } from 'lang';
import { currentLang$ } from 'models/language';
import { getHiringDaysCount } from 'utils/datesСalculation';
import { getPriceRangeTextInTable } from 'utils/helpers/formatPrices';

import { useMatchMedia } from 'libs/useMatchMedia';

import { T8y } from 'ui';

import style from './priceTableBody.module.scss';

const MobileItems = ({
  selectingDetails,
  allPrices,
  showDiscount,
  isNotAvailable,
}) => {
  const { products } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);
  const currentLangState = useStore(currentLang$);

  const hiringDaysCount = useMemo(() => {
    if (selectingDetails?.dates?.start && selectingDetails?.dates?.end) {
      return Number(getHiringDaysCount(selectingDetails));
    }

    return 0;
  }, [selectingDetails]);

  const activeRangeIndex = useMemo(() => {
    const elIndex = allPrices.findIndex(
      (el) =>
        el.period_from <= hiringDaysCount && el.period_to >= hiringDaysCount,
    );

    //handle case when ETH have no last range
    if (isETH && elIndex === -1) {
      return 0;
    }

    return elIndex !== -1 ? elIndex : allPrices.length - 1;
  }, [allPrices, hiringDaysCount]);

  const secondTitleIsBigMargin = !isSmallScreen && currentLangState !== 'en-gb';

  return (
    <div className={style.priceItems}>
      <div className={style.fakePricesWrapper}>
        {allPrices.map((el, index) => (
          <div
            key={index}
            className={clsx(
              showDiscount ? style.fakePriceWithDiscount : style.fakePrice,
              secondTitleIsBigMargin && style.fakePriceBig,
              !!selectingDetails?.dates?.start &&
                !!selectingDetails?.dates?.end &&
                activeRangeIndex === index &&
                style.fakePriceActive,
            )}
          />
        ))}
      </div>
      <div className={style.rangeItem}>
        <T8y variant="t3" color="newGray" mb2 bold>
          {products.hireTime}
        </T8y>
        <div className={style.rangeItem}>
          {allPrices.map((el, index) => (
            <div
              key={index}
              className={clsx(
                style.firstPrice,
                showDiscount && style.firstPriceWithDiscount,
              )}
            >
              <T8y variant="t3" bold className={style.period}>
                {getPriceRangeTextInTable(
                  el,
                  products,
                  settings.fixedRangesPrice,
                )}
              </T8y>
              <span className={style.dots}>
                .............................................................................................................................................................................................................................................................
              </span>
            </div>
          ))}
        </div>
      </div>
      <div>
        <T8y
          variant="t3"
          color="newGray"
          bold
          className={clsx(
            style.prices,
            secondTitleIsBigMargin ? style.hireTitleBig : style.hireTitleSmall,
          )}
        >
          {products.price}
        </T8y>
        <div>
          {allPrices.map((el, index) => (
            <div
              key={index}
              className={clsx(
                style.prices,
                showDiscount && style.pricesWithDiscount,
              )}
            >
              {showDiscount && (
                <T8y variant="t5" color="midGray" className={style.oldPrice}>
                  {branding.currencySymbol}
                  {el.oldPrice ? parseFloat(el.oldPrice).toFixed(2) : 0}
                </T8y>
              )}
              <T8y
                color={isNotAvailable ? 'newGray' : 'primary'}
                variant="t3"
                className={
                  showDiscount ? style.currPriceWithDiscount : style.currPrice
                }
                bold
              >
                {branding.currencySymbol}
                {el.price ? parseFloat(el.price).toFixed(2) : 0}
              </T8y>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileItems;
