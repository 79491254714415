import React, { useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { SMALL_SCREEN } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import { useStore } from 'effector-react';
import {
  sendClickOnPhoneIcon,
  sendEventWithEventLabel,
  sendGAEvent,
} from 'googleAnalytics';
import { Lang } from 'lang';
import { jobTypes$ } from 'models/jobTypes';
import { $isUserLoggedIn } from 'models/user';

import { useMatchMedia } from 'libs/useMatchMedia';

import Hamburger from '../Hamburger';
import MobileStaticLinks from '../MobileStaticLinks';
import { setIsMenuOpen } from '../model';
import SubMenuCompany from '../SubMenuCompany';
import SubMenuJobTypes from '../SubMenuJobTypes';
import SubMenuMobile from '../SubMenuMobile';
import SubMenuProfile from '../SubMenuProfile';
import SubMenuRegion from '../SubMenuRegion';
import SubMenuTraining from '../SubMenuTraining';

import { T8y } from 'ui';

import { ReactComponent as CallIcon } from 'assets/images/icon/phoneWhite.svg';

import style from './mobileMenu.module.scss';

const MobileNav = ({
  openId,
  setOpenId,
  isMenuOpen,
  close,
  isSearchOpen,
  isShowCartSing,
}) => {
  const {
    products,
    header: { nav },
  } = Lang();

  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const isUserLoggedIn = useStore($isUserLoggedIn);
  const jobTypes = useStore(jobTypes$);

  const toggleMenu = useCallback(
    (e) => {
      if (!e.target) {
        return;
      }

      if (e.target.classList.contains('disable-scrolling')) {
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    if (isMenuOpen) {
      document.querySelector('body').classList.add('disable-scrolling');

      document.addEventListener('click', toggleMenu);
    } else {
      document.querySelector('body').classList.remove('disable-scrolling');

      document.removeEventListener('click', toggleMenu);
      openId === 4 && setOpenId(0);
    }
    return () => {
      document.querySelector('body').classList.remove('disable-scrolling');

      document.removeEventListener('click', toggleMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const handleMobileMenuClick = useCallback(() => {
    if (isSmallScreen && !isMenuOpen) {
      sendGAEvent('mobile_hamburger_menu_click');
    }

    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen, isSmallScreen]);

  return (
    <>
      <Hamburger open={isMenuOpen} onClick={handleMobileMenuClick} />
      <div
        className={clsx(
          style.mobileMenu,
          isMenuOpen && style.open,
          isSearchOpen && style.mobileMenuSearchOpen,
        )}
      >
        <div className={style.linksWrapper}>
          <SubMenuMobile
            id={1}
            openId={openId}
            setOpenId={setOpenId}
            close={close}
          />

          {(openId === 0 || openId === 2) && settings.showTrainingsInMenu && (
            <SubMenuTraining
              id={2}
              openId={openId}
              setOpenId={setOpenId}
              close={close}
            />
          )}

          {(openId === 0 || openId === 5) && !!jobTypes.length && (
            <SubMenuJobTypes
              id={5}
              openId={openId}
              setOpenId={setOpenId}
              close={close}
            />
          )}

          <SubMenuCompany
            id={3}
            openId={openId}
            setOpenId={setOpenId}
            close={close}
          />

          {openId === 0 && (
            <T8y
              as="a"
              href={branding.blogLink || '#'}
              onClick={() => {
                sendEventWithEventLabel('regional_page_header_about', nav.blog);
                close();
              }}
              color="light"
              variant="t1"
              className={style.categoryOnly}
              capitalize
            >
              {nav.blog}
            </T8y>
          )}

          {settings.showCustomizeInventory && (
            <SubMenuRegion id={4} openId={openId} setOpenId={setOpenId} />
          )}

          {branding.companyPhoneNumber && openId === 0 && (
            <T8y
              as="a"
              href={`tel:${branding.companyPhoneNumberCall}`}
              className={clsx(
                style.mobileMenuTop,
                style.border,
                style.callItem,
              )}
              onClick={() => {
                sendClickOnPhoneIcon('header');
              }}
              mb2
            >
              <T8y variant="t1" color="light">
                {products.call} {branding.companyPhoneNumber}
              </T8y>
              <CallIcon width={19} />
            </T8y>
          )}

          {isUserLoggedIn && (
            <SubMenuProfile
              id={6}
              openId={openId}
              setOpenId={setOpenId}
              close={close}
            />
          )}

          {openId === 0 && (
            <MobileStaticLinks isShowCartSing={isShowCartSing} close={close} />
          )}
        </div>
      </div>
    </>
  );
};

export default MobileNav;
