export default (enquiryRef, isCarbonBar) => {
  if (!enquiryRef || !enquiryRef.current) {
    return;
  }

  const additionalOffset = isCarbonBar ? 36 : 0;

  window.scrollTo({
    left: 0,
    top: enquiryRef.current.offsetTop - 170 - additionalOffset,
    behavior: 'smooth',
  });
};
