import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { LAPTOP_SCREEN, SMALL_SCREEN } from 'consts';
import { BrandingContext, SettingsContext } from 'contexts';
import MobileItems from 'pages/product/MainBlock/MobileItem';
import ProductPricesTableItem from 'pages/product/MainBlock/ProductPricesTableItem';
import { getFormattedPricesForTable } from 'utils/helpers/formatPrices';

import { useMatchMedia } from 'libs/useMatchMedia';

import { T8y } from 'ui';

import { ReactComponent as InfoIcon } from 'assets/images/icon/infoBlackSign.svg';

import style from './productPricesTable.module.scss';

const ProductPricesTable = ({
  selectingDetails,
  showDiscount,
  isNotAvailable,
  product,
  isCart,
}) => {
  const settings = useContext(SettingsContext);
  const branding = useContext(BrandingContext);

  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const sortedPrices = useMemo(() => {
    if (!product?.prices) {
      return [];
    }

    return [...product.prices].sort((a, b) =>
      a.period_from > b.period_from ? -1 : 1,
    );
  }, [product]);

  const allPrices = useMemo(() => {
    return getFormattedPricesForTable({
      sortedPrices,
      additionalPrices: null,
      showInfinitySignInPrices: settings.showInfinitySignInPrices,
    });
  }, [settings.showInfinitySignInPrices, sortedPrices]);

  return (
    <div
      className={clsx(
        style.productPricesTable,
        isCart && style.productPricesTableCart,
      )}
    >
      {isSmallScreen || (isCart && isLaptop) ? (
        <MobileItems
          selectingDetails={selectingDetails}
          allPrices={allPrices}
          showDiscount={showDiscount}
          isNotAvailable={isNotAvailable}
          isCart={isCart}
        />
      ) : (
        <div
          className={
            allPrices.length < 6
              ? style.pricesWrapper
              : style.pricesWrapperTable
          }
        >
          {allPrices.map((el, index) => (
            <ProductPricesTableItem
              key={index}
              el={el}
              showDiscount={showDiscount}
              isNotAvailable={isNotAvailable}
              allPrices={allPrices}
            />
          ))}
        </div>
      )}
      <div
        className={clsx(
          style.extraDayNoteWrapper,
          (isSmallScreen || (isCart && isLaptop)) &&
            style.extraDayNoteWrapperMobile,
        )}
      >
        {/*<InfoIcon />*/}
        {/*<T8y variant="t3" smVariant="t5" className={style.extraDayNote}>*/}
        {/*  One extra day will cost in additional &nbsp;*/}
        {/*  <T8y*/}
        {/*    variant="t3"*/}
        {/*    smVariant="t5"*/}
        {/*    color={isNotAvailable ? 'newGray' : 'primary'}*/}
        {/*    bold*/}
        {/*  >*/}
        {/*    {branding.currencySymbol}*/}
        {/*    {product?.additional_prices?.price_per_day?.toFixed(2)}*/}
        {/*  </T8y>*/}
        {/*</T8y>*/}
      </div>
    </div>
  );
};

export default ProductPricesTable;
